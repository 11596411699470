'use client';

import React from 'react';
import BannerHome from './banner';
import Creator from './creator';
import ExploreTemplate from './explore';
import Models from './model';
import Workflow from './workflow';

const HomePage: React.FC = () => {
  return (
    <div className="px-[20px]">
      <BannerHome />
      <Workflow />
      <Models />
      <Creator />
      <ExploreTemplate />
    </div>
  );
}

export default HomePage;