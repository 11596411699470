import { Button, Input } from '@nextui-org/react';
import { useSearchParams } from 'next/navigation';
import { Dispatch, SetStateAction, useRef } from 'react';
import React, { useEffect, useState } from 'react';

import { HeaderSearchIcon, IconFilter, IconLeft } from '@/assets';
import useCreateQueryString from '@/hooks/useCreateQueryString';
import cachedLocalStorage from '@/libs/localStorage';
import { useAuth } from '@/provider/UserProvider';

import { Buttons } from '../button';
import HotTagList from '../HotTagList';
import { useSQuery } from '@/utils';
import { getHotTags } from '@/services/tags';
import { debounce } from 'lodash';

export enum SORT {
  desc = 'DESC',
  asc = 'ASC',
}

const ListBarUsecase: React.FC<{
  openFilter: boolean;
  setOpenFilter: Dispatch<SetStateAction<boolean>>;
  handleClearFilter: () => void;
  hasTags?: boolean;
  isFilterNsfw?: boolean;
  setIsFilterNsfw?: (value: boolean) => void;
}> = ({
  openFilter,
  setOpenFilter,
  handleClearFilter,
  hasTags = true,
  isFilterNsfw,
}) => {
  const refSearch = useRef<any>(null);
  const searchParams = useSearchParams();
  const [searchValue, setSearchValue] = useState(searchParams.get('name') ?? '');

  // const [isOpenModelNsfw, setIsOpenModelNsfw] = useState(false);

  const { auth, setAuth, isLoginClient } = useAuth();
  const createQueryString = useCreateQueryString();
  
  const { data } = useSQuery({
    queryKey: ['list-tag-hot'],
    queryFn: () => getHotTags(),
    keepPreviousData: true,
  });
  const listTags = data?.data || [];

  useEffect(() => {
    if (isLoginClient) {
      setAuth({ ...auth, user: { ...auth.user, isFilterNsfw } });
      cachedLocalStorage.setItem('isFilterNsfw', isFilterNsfw ? 'true' : 'false');
    }
  }, [isFilterNsfw, isLoginClient]);

  useEffect(() => {
    if (isFilterNsfw) {
      createQueryString(['nsfw'], [isFilterNsfw], { scroll: false });
      // setIsOpenModelNsfw(false);
    } else {
      createQueryString('', '', { scroll: false }, 'nsfw');
      // setIsOpenModelNsfw(false);
    }
  }, [isFilterNsfw]);

  return (
    <div>
      <div className="flex items-center">
        <div className="left-0 z-30 flex sm:flex-wrap w-full items-center gap-3 duration-300" id="navbar">
          <div className="flex sm:w-full">
            <Button
              isIconOnly
              startContent={openFilter ? <IconLeft /> : <IconFilter className="sm:scale-80" />}
              className="m-0 mr-3 flex cursor-pointer items-center rounded-[100px] border-1 border-gray-200 bg-white text-14 font-normal sm:mr-2 sm:h-[36px]  sm:w-[36px] sm:min-w-0"
              onClick={() => setOpenFilter(!openFilter)}
            />
            {searchParams.toString() && (
              <Buttons
                typeBtn="cancel"
                className="mr-3 text-15 font-semibold md:hidden"
                onClick={() => {
                  if (refSearch.current) refSearch.current.value = '';
                  handleClearFilter();
                }}
              >
                Clear all
              </Buttons>
            )}

            <div className="max-w-[260px] mr-3 rounded-[100px] bg-white border-1 border-gray-200">
              <Input
                defaultValue={searchValue}
                classNames={{
                  input: ['dark:placeholder:text-white font-normal text-14 pl-1', 'rounded-[100px]'],
                  innerWrapper: '!bg-transparent rounded-[100px]',
                  inputWrapper:
                    '!bg-transparent rounded-[100px] shadow-none h-[40px] sm:h-[36px] sm:min-h-[36px] min-w-[260px] sm:w-[100%] sm:min-w-0',
                }}
                onChange={debounce((e) => {
                  createQueryString('name', e.target.value, { scroll: false });
                  setSearchValue(e.target.value);
                }, 500)}
                ref={refSearch}
                startContent={<HeaderSearchIcon />}
                placeholder="Search for templates"
              />
            </div>
            {/* <div className="relative">
              <span
                onClick={() => {
                  setIsOpenModelNsfw(!isOpenModelNsfw);
                }}
              >
                <div className="cursor-pointer rounded-[100px] border-1 border-gray-200 bg-white p-[7px] text-[14px] text-black aria-checked:border-black sm:p-[4px]">
                  <IconFilterNsfw className="sm:scale-90" />
                </div>
              </span>
              {isOpenModelNsfw && (
                <div className="absolute top-[55px] min-w-[275px] rounded-[12px] bg-white p-4 shadow-lg">
                  <div>
                    <p className="text-[16px] font-semibold leading-7">Browsing mode</p>
                    <p className="flex items-center justify-between pt-4">
                      <span className="text-14 leading-[22.5px]">Show NSFW content</span>
                      <Switch
                        size="sm"
                        isSelected={isFilterNsfw}
                        onValueChange={
                          () => {
                            if (isLogin) {
                              if (setIsFilterNsfw !== undefined) {
                                setIsFilterNsfw(!isFilterNsfw);
                              }
                            } else {
                              toast.error('You must login to view this content!');
                            }
                          }
                        }
                      />
                    </p>
                  </div>
                </div>
              )}
            </div> */}
          </div>
          {hasTags && (
            <div aria-checked={!!searchParams.toString()} className="w-[calc(100%-380px)] sm:!w-full aria-checked:w-[calc(100%-494px)]">
              <HotTagList list={listTags} />
            </div>
          )}
        </div>
      </div>
    </div>
  );
};

export default ListBarUsecase;
