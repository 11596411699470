import CardWorkflow from '@/components/card/CardWorkflow';
import CardWorkflowForOldModel from '@/components/card/CardWorkflowForOldModel';
import RemixCommon from '@/layouts/remix-common';
import { SIDEBAR_MENU_LINK } from '@/layouts/sidebar';
import { useLayout } from '@/provider/LayoutProvider';
import { getListShowCase } from '@/services/workflow';
import { useSQuery } from '@/utils';
import { useDisclosure } from '@nextui-org/react';
import Link from 'next/link';
import React, { useState } from 'react';
import { toast } from 'react-toastify';
import WorkflowSkeleton from './Skeleton';

const Workflow: React.FC = () => {
  const { setIsLoadingBar } = useLayout();
  const [modelSelect, setModelSelect] = useState<any>();
  const { data: workflowList, isLoading } = useSQuery({
    queryKey: ['workflowList'],
    queryFn: () => getListShowCase({ size: 5, offset: 0 }),
    keepPreviousData: true,
    staleTime: 5000,
  });

  const useModalRemix = useDisclosure({
    onClose() {
      document.body.style.removeProperty('overflow');
      setModelSelect(undefined);
    },
  });

  const { onOpen } = useModalRemix;

  const handleOpenModel = (model: any) => {
    if (model?.status === 4) {
      toast.error('Model maintenance!');
    } else {
      document.body.style.overflow = 'hidden';
      setModelSelect(model);
      onOpen();
    }
  };

  return (
    <div className="mt-[40px]">
      {modelSelect && <RemixCommon useModalRemix={useModalRemix} modelSelect={modelSelect} />}
      <div className="flex items-center justify-between mb-4">
        <h3 className="text-[24px] font-semibold sm:text-[16px]">AI Tools</h3>
        <Link
          className="py-[5px] px-5 rounded-full border-1 border-[#F4D0C4] transition-all hover:border-orange-500 bg-white text-orange-500 text-[16px] sm:text-[13px] font-semibold"
          href={SIDEBAR_MENU_LINK.workflow}
          onClick={() => setIsLoadingBar(true)}
        >
          View all
        </Link>
      </div>
      {isLoading ? (
        <WorkflowSkeleton />
      ) : (
        <div className="grid gap-4 sm:gap-2 grid-cols-5 2xl:grid-cols-4 md:grid-cols-2">
          {workflowList?.items?.map((w: any) => (
            <div key={w.id} className="2xl:last:hidden">
              {w.isWorkflow ? (
                <CardWorkflow
                  totalUse={w.totalUsedTimes}
                  totalLike={w.totalReactions}
                  id={w.id}
                  image={w?.previews[0]}
                  title={w.name}
                  provider={w.provider}
                  nodeCount={w.workflow?.nodeCount}
                />
              ) : (
                <div onClick={() => handleOpenModel(w)}>
                  <CardWorkflowForOldModel
                    totalUse={w.totalUsedTimes}
                    totalLike={w.totalReactions}
                    url={w.picture ?? w.cover}
                    title={w.name}
                    provider={w.provider}
                  />
                </div>
              )}
            </div>
          ))}
        </div>
      )}
    </div>
  );
};

export default Workflow;
