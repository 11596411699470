import { useDisclosure } from '@nextui-org/react';
import Link from 'next/link';
import React from 'react';
import LazyLoad from 'react-lazyload';

import RemixCommon from '@/layouts/remix-common';
import { SIDEBAR_MENU_LINK } from '@/layouts/sidebar';
import { useLayout } from '@/provider/LayoutProvider';
import type { Lora } from '@/types';
import type { UserProfile } from '@/types/user';
import { formatCash } from '@/utils';

import { Images } from '..';
import { IconHeartNotLiked, IconRun } from '../assets';
import AvatarPlaceholder from '../avatar-default';

interface TypeModel {
  [key: number]: string;
}

const VIDEO_TYPES = ['mp4', '3gp', 'ogg'];

const TYPEMODEL: TypeModel = {
  1: 'CHECKPOINT',
  2: 'LORA',
};

const CardModel: React.FC<{
  url: string;
  title: string;
  totalUse: number;
  totalLike: number;
  owner: UserProfile;
  id: number | string;
  type: number;
  baseModel: string;
  model: Lora;
}> = ({ url, title, totalUse, totalLike, owner, id, type, baseModel, model }) => {
  const { setIsLoadingBar } = useLayout();
  const urlExtension = url.split('.');
  const urlType = urlExtension[urlExtension.length - 1];
  const isVideoURL = urlType && VIDEO_TYPES.includes(urlType);

  const useModalRemix = useDisclosure({
    onClose() {
      document.body.style.removeProperty('overflow');
    },
  });
  const { onOpen } = useModalRemix;

  const handleUseUseCase = async () => {
    document.body.style.overflow = 'hidden';
    onOpen();
  };

  return (
    <>
      <RemixCommon useModalRemix={useModalRemix} modelLoraSelected={model} />
      <div
        style={{ display: 'inherit' }}
        className="group select-none rounded-[8px] border-1 border-[#E8E8E8] bg-white transition-all hover:border-orange-500"
      >
        <Link
          href={`${SIDEBAR_MENU_LINK.aiModel}/${id}`}
          onClick={() => setIsLoadingBar(true)}
          className=" cursor-pointer "
        >
          <div className="relative overflow-hidden rounded-t-[8px] before:block before:pb-[calc((240/210)*100%)]">
            {isVideoURL ? (
              <LazyLoad className="absolute left-0 top-0 h-full w-full" offset={200} once>
                <video autoPlay loop muted className="h-full w-full object-cover">
                  <source src={url} />
                </video>
              </LazyLoad>
            ) : (
              <Images
                className="absolute left-0 top-0 h-full w-full object-cover object-top"
                alt="workflow"
                src={url}
              />
            )}
            <div className="absolute bottom-2 left-2 z-10 flex items-center gap-2 rounded-full bg-[rgba(0,0,0,0.5)] px-2 py-1">
              <div className="flex items-center">
                <IconHeartNotLiked width={20} height={20} color="white" />
                <span className="ml-[4px] text-[14px] text-white md:text-12">
                  {formatCash(totalLike)}
                </span>
              </div>
              <span className="h-1 w-1 rounded-full bg-[#9DA3B5]" />
              <div className="flex items-center">
                <IconRun color="white" />
                <span className="ml-[4px] text-[14px] text-white md:text-12">
                  {formatCash(totalUse)}
                </span>
              </div>
            </div>
            <div className="absolute left-2 top-2 z-10 flex items-center gap-[2px] rounded-full bg-[rgba(0,0,0,0.5)] px-2 text-white">
              <span className="text-[12px] md:text-[10px]">{TYPEMODEL[type]}</span>|
              <span className="text-[12px] md:text-[10px]">{baseModel}</span>
            </div>
          </div>
        </Link>

        <div className="p-3">
          <div className="flex items-center">
            <div className="h-[16px] w-[16px] relative rounded-full">
              <AvatarPlaceholder
                src={owner.picture}
                className="w-full h-full rounded-full object-cover"
                address={owner.publicAddress}
                decor={owner.avatarDecoration}
                decorScale={8}
              />
            </div>
            <p className="ml-1 line-clamp-1 text-[12px] font-semibold">
              {owner.username || 'Unnamed'}
            </p>
          </div>
          <div className="mt-2 flex items-center justify-between">
            <div className="pr-2">
              <p className="line-clamp-1 sm:max-w-[80px] h-[22px] text-[15px] font-semibold leading-[22px] sm:h-[18px] sm:text-[13px] sm:leading-[18px]">
                {title}
              </p>
            </div>
            <div
              className="z-20 inline-block cursor-pointer rounded-[8px] border-1 border-gray-800 px-3 py-[3px] text-[12px] transition-all group-hover:border-orange-500 group-hover:bg-orange-500 group-hover:text-white"
              onClick={() => handleUseUseCase()}
            >
              Run
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default CardModel;
