import { Skeleton } from "@nextui-org/react";

const CreatorSkeleton = () => {

  return (
    <div className="grid grid-cols-3 md:grid-cols-1 gap-4">
      <div className="rounded-lg px-4 py-1 bg-white border-1 border-gray-200">
        <div className="py-3 flex justify-between items-center">
          <div className="flex items-center gap-2">
            <Skeleton className="w-[40px] h-[40px] rounded-full" />
            <div>
              <Skeleton className="w-[100px] h-[20px] rounded-sm mb-1" />
              <Skeleton className="w-[140px] h-[20px] rounded-sm" />
            </div>
          </div>
          <Skeleton className="w-[40px] h-[40px] rounded-lg" />
        </div>
        <div className="py-3 flex justify-between items-center">
          <div className="flex items-center gap-2">
            <Skeleton className="w-[40px] h-[40px] rounded-full" />
            <div>
              <Skeleton className="w-[100px] h-[20px] rounded-sm mb-1" />
              <Skeleton className="w-[140px] h-[20px] rounded-sm" />
            </div>
          </div>
          <Skeleton className="w-[40px] h-[40px] rounded-lg" />
        </div>
        <div className="py-3 flex justify-between items-center">
          <div className="flex items-center gap-2">
            <Skeleton className="w-[40px] h-[40px] rounded-full" />
            <div>
              <Skeleton className="w-[100px] h-[20px] rounded-sm mb-1" />
              <Skeleton className="w-[140px] h-[20px] rounded-sm" />
            </div>
          </div>
          <Skeleton className="w-[40px] h-[40px] rounded-lg" />
        </div>
      </div>
      <div className="rounded-lg md:hidden bg-white px-4 py-1 border-1 border-gray-200">
        <div className="py-3 flex justify-between items-center">
          <div className="flex items-center gap-2">
            <Skeleton className="w-[40px] h-[40px] rounded-full" />
            <div>
              <Skeleton className="w-[100px] h-[20px] rounded-sm mb-1" />
              <Skeleton className="w-[140px] h-[20px] rounded-sm" />
            </div>
          </div>
          <Skeleton className="w-[40px] h-[40px] rounded-lg" />
        </div>
        <div className="py-3 flex justify-between items-center">
          <div className="flex items-center gap-2">
            <Skeleton className="w-[40px] h-[40px] rounded-full" />
            <div>
              <Skeleton className="w-[100px] h-[20px] rounded-sm mb-1" />
              <Skeleton className="w-[140px] h-[20px] rounded-sm" />
            </div>
          </div>
          <Skeleton className="w-[40px] h-[40px] rounded-lg" />
        </div>
        <div className="py-3 flex justify-between items-center">
          <div className="flex items-center gap-2">
            <Skeleton className="w-[40px] h-[40px] rounded-full" />
            <div>
              <Skeleton className="w-[100px] h-[20px] rounded-sm mb-1" />
              <Skeleton className="w-[140px] h-[20px] rounded-sm" />
            </div>
          </div>
          <Skeleton className="w-[40px] h-[40px] rounded-lg" />
        </div>
      </div>
      <div className="rounded-lg md:hidden bg-white px-4 py-1 border-1 border-gray-200">
        <div className="py-3 flex justify-between items-center">
          <div className="flex items-center gap-2">
            <Skeleton className="w-[40px] h-[40px] rounded-full" />
            <div>
              <Skeleton className="w-[100px] h-[20px] rounded-sm mb-1" />
              <Skeleton className="w-[140px] h-[20px] rounded-sm" />
            </div>
          </div>
          <Skeleton className="w-[40px] h-[40px] rounded-lg" />
        </div>
        <div className="py-3 flex justify-between items-center">
          <div className="flex items-center gap-2">
            <Skeleton className="w-[40px] h-[40px] rounded-full" />
            <div>
              <Skeleton className="w-[100px] h-[20px] rounded-sm mb-1" />
              <Skeleton className="w-[140px] h-[20px] rounded-sm" />
            </div>
          </div>
          <Skeleton className="w-[40px] h-[40px] rounded-lg" />
        </div>
        <div className="py-3 flex justify-between items-center">
          <div className="flex items-center gap-2">
            <Skeleton className="w-[40px] h-[40px] rounded-full" />
            <div>
              <Skeleton className="w-[100px] h-[20px] rounded-sm mb-1" />
              <Skeleton className="w-[140px] h-[20px] rounded-sm" />
            </div>
          </div>
          <Skeleton className="w-[40px] h-[40px] rounded-lg" />
        </div>
      </div>
    </div>
  );
}

export default CreatorSkeleton;