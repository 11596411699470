import 'swiper/css';
import 'swiper/css/effect-fade';
import 'swiper/css/navigation';
import 'swiper/css/autoplay';

import { Button, Input, useDisclosure } from '@nextui-org/react';
import { useQueryClient } from '@tanstack/react-query';
import mixpanel from 'mixpanel-browser';
import Image from 'next/image';
import Link from 'next/link';
import React, { useEffect, useState } from 'react';
import { Autoplay, EffectFade, Navigation } from 'swiper/modules';
import { Swiper, SwiperSlide } from 'swiper/react';

import {
  BgBannerHome,
  IconDices,
  IconDices16px,
  IconGenHomepage,
  IconSlideLeft,
  IconSlideRight,
} from '@/assets';
import { Buttons, Images } from '@/components';
import ClientOnly from '@/components/client-only';
import { MIXPANEL_TRACK } from '@/configs/constant';
import { useConnectFreeAccount } from '@/hooks/useConnectFreeAccount';
import useWindowSize from '@/hooks/useWindowSize';
import RemixCommon from '@/layouts/remix-common';
import { useAuth } from '@/provider/UserProvider';
import { getSuggestPrompt } from '@/services';
import { useLayout } from '@/provider/LayoutProvider';

const BannerHome: React.FC = () => {
  const { isMobileView } = useWindowSize();
  const [formData, setFormData] = useState('');
  const { isLogin } = useAuth();
  const { connectFreeAcc } = useConnectFreeAccount();
  const [loading, setLoading] = useState(false);
  const queryClient = useQueryClient();
  const swiperRef = React.useRef<any>(null);
  const { setIsLoadingBar } = useLayout();

  const useModalRemix = useDisclosure({
    onClose() {
      queryClient.removeQueries(['promptCache']);
      document.body.style.removeProperty('overflow');
    },
  });
  const { onOpen } = useModalRemix;

  const handleInputChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    const { value } = e.target;
    setFormData(value);
  };

  const suggestPrompt = async () => {
    setLoading(true);

    if (formData) {
      try {
        const res = await getSuggestPrompt(formData);
        if (res.status === 200) {
          setFormData(res.data);
        }
      } catch (error) {
        setLoading(false);
      }
    } else {
      try {
        const res = await getSuggestPrompt('');
        if (res.status === 200) {
          setFormData(res.data);
        }
      } catch (error) {
        setLoading(false);
      }
    }

    setLoading(false);
  };

  const handleOpenModel = () => {
    document.body.style.overflow = 'hidden';
    onOpen();
  };

  const listBanner = [
    {
      image: 'https://data.airight.io/home/banner/Unique-Avatar-Decorations.png',
      link: '/shop',
      target: '_self'
    },
    {
      image: 'https://data.airight.io/home/banner/proof-webbanner.png',
      link: '',
      target: '_self'
    },
    {
      image: 'https://data.airight.io/home/banner/banner_pricing.png',
      link: '/pricing',
      target: '_self'
    },
    {
      image: 'https://data.airight.io/home/banner/banner_workflow.png',
      link: 'https://docs.google.com/forms/d/1SdMQDMaFlkDcbysFaaC6xeNmlazY6ldHwhMVEUx4O5Q/edit',
      target: '_blank'
    },
  ];

  useEffect(() => {
    if (!isLogin) {
      connectFreeAcc();
    }
  }, [isLogin]);

  return (
    <div className="relative mt-5 overflow-hidden rounded-[16px]">
      <RemixCommon useModalRemix={useModalRemix} />
      <Image
        alt="banner"
        className="absolute left-0 top-0 h-full w-full object-cover"
        src={BgBannerHome}
      />
      <div className="relative mx-auto flex w-full max-w-[1480px] items-center justify-between lg:flex-col-reverse">
        <div className="max-w-[630px] px-6 py-5 lg:max-w-full lg:px-5 md:max-w-full md:px-3">
          <h1 className="mb-7 text-[32px] font-bold 2xl:text-[26px] lg:mb-2 sm:text-[16px]">
            <span className="inline-block bg-gradient-to-r from-[#7F41E2] to-[#EF743F] bg-clip-text text-transparent">
              FREE ONLINE
            </span>{' '}
            AI ART GENERATOR & MODELS HOSTING SITE!
          </h1>
          <div className="flex h-[60px] w-full items-center justify-between rounded-[10px] border-1 border-[#E8E8E7] bg-white 2xl:h-[54px] sm:hidden">
            <Input
              classNames={{
                inputWrapper: 'border-none shadow-none md:pr-1 md:pl-2',
                input: 'text-18 md:!text-[13px] 2xl:!text-[16px] placeholder-gray-400',
                innerWrapper: 'md:gap-0',
              }}
              variant="bordered"
              placeholder="What do you want to create today?"
              value={formData}
              onChange={handleInputChange}
              endContent={
                <Button
                  isIconOnly
                  variant="light"
                  isLoading={loading}
                  className="focus:outline-none md:h-unit-5 md:w-unit-5 md:min-w-unit-5"
                  type="button"
                  onClick={suggestPrompt}
                >
                  {loading ? '' : isMobileView ? <IconDices16px /> : <IconDices />}
                </Button>
              }
            />
            <Buttons
              typeBtn="primary"
              style={{ background: 'primary' }}
              className="mr-3 h-[36px] min-w-[130px] rounded-[100px] px-[20px] font-semibold opacity-100"
              // isDisabled={!formData}
              onClick={() => {
                mixpanel.track(MIXPANEL_TRACK.GENERATE_OPEN);
                queryClient.setQueryData(['promptCache'], formData);
                handleOpenModel();
              }}
            >
              <span className="text-15 font-semibold md:!text-[12px]">Generate</span>
            </Buttons>
          </div>
          <div className="hidden w-full rounded-[12px] border-1 border-gray-200 bg-white px-4 py-3 drop-shadow-xl sm:block">
            <Input
              classNames={{
                inputWrapper: 'border-none shadow-none pt-0 px-0 h-fit items-start',
                input: 'text-[14px]',
                innerWrapper: 'gap-0',
              }}
              variant="bordered"
              placeholder="What do you want to create today?"
              value={formData}
              onChange={handleInputChange}
            />
            <div className="flex items-center justify-end gap-1 pt-3">
              <Button
                isIconOnly
                variant="light"
                isLoading={loading}
                className="focus:outline-none"
                type="button"
                onClick={suggestPrompt}
              >
                {loading ? '' : <IconDices />}
              </Button>
              <Buttons
                typeBtn="primary"
                className="mt-[-2px] h-[36px] rounded-[100px] px-[10px] py-1 font-semibold"
                onClick={() => {
                  if (!isLogin) {
                    connectFreeAcc();
                  }
                  mixpanel.track(MIXPANEL_TRACK.GENERATE_OPEN);
                  queryClient.setQueryData(['promptCache'], formData);
                  handleOpenModel();
                }}
              >
                <span className="mr-1 flex items-center gap-[10px] text-13">
                  <IconGenHomepage /> Generate
                </span>
              </Buttons>
            </div>
          </div>
        </div>
        <div className="max-h-[250px] w-[700px] items-center rounded-xl px-6 lg:mt-5 lg:w-full lg:px-5 md:mt-2 md:h-auto md:px-2">
          {/* <Image alt="banner" className="object-contain" src={BannerHomeImg} /> */}
          <div className="group/swiper relative mx-auto flex w-auto items-center rounded-xl">
            <ClientOnly>
              <Swiper
                className=""
                effect="fade"
                slidesPerView={1}
                loop
                modules={[Navigation, Autoplay, EffectFade]}
                onSwiper={(swiper: any) => {
                  swiperRef.current = swiper;
                }}
                autoplay={{ delay: 5000 }}
              >
                {listBanner.map((item) => (
                  <SwiperSlide>
                    <Link onClick={() => { item.target === '_self' && setIsLoadingBar(true) }} target={item.target} href={item.link} className="flex justify-center">
                      <Images
                        alt="banner"
                        className="max-h-[250px] max-w-[650px] rounded-xl object-fill lg:max-w-full"
                        src={item.image}
                      />
                    </Link>
                  </SwiperSlide>
                ))}
              </Swiper>
            </ClientOnly>
            <div
              className="absolute -left-[20px] top-[50%] z-[1] flex h-[40px] w-[40px] -translate-x-[10px] -translate-y-[50%] cursor-pointer items-center justify-center rounded-[5px] border-1 border-white bg-orange-500 opacity-0 transition-all hover:opacity-80 group-hover/swiper:translate-x-0 group-hover/swiper:opacity-100 lg:hidden sm:-left-[15px] sm:h-[30px] sm:w-[30px] sm:translate-x-0"
              onClick={() => swiperRef.current.slidePrev()}
            >
              <IconSlideLeft />
            </div>
            <div
              className="absolute -right-[20px] top-[50%] z-[1] ml-2 flex h-[40px] w-[40px] -translate-y-[50%] translate-x-[10px] cursor-pointer items-center justify-center rounded-[5px] border-1 border-white bg-orange-500 opacity-0 transition-all hover:opacity-80 group-hover/swiper:translate-x-0 group-hover/swiper:opacity-100 lg:hidden sm:-right-[15px] sm:h-[30px] sm:w-[30px] sm:translate-x-0"
              onClick={() => swiperRef.current.slideNext()}
            >
              <IconSlideRight />
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default BannerHome;
