import Link from 'next/link';
import React from 'react';

import CardModel from '@/components/card/CardModel';
import { SIDEBAR_MENU_LINK } from '@/layouts/sidebar';
import { useLayout } from '@/provider/LayoutProvider';
import { getLoras } from '@/services';
import type { Lora } from '@/types';
import { useSQuery } from '@/utils';

import ModelSkeleton from './Skeleton';

const Models: React.FC = () => {
  const { setIsLoadingBar } = useLayout();

  const { data: models, isLoading } = useSQuery({
    queryKey: ['homeModels'],
    queryFn: () => getLoras(5, 0, `{"runCount": "DESC" }`),
    keepPreviousData: true,
    staleTime: 5000,
  });

  return (
    <div className="relative mt-[25px]">
      <div
        style={{
          background: 'linear-gradient(107deg, #5AFFEB 2.5%, #D86AFF 54.56%, #FFA265 106.05%)',
        }}
        className="absolute right-0 top-0 z-[-1] h-[522px] w-[660px] opacity-[0.25] blur-[250px]"
      />
      <div className="mb-4 flex items-center justify-between">
        <h3 className="text-[24px] font-semibold sm:text-[16px]">Models</h3>
        <Link
          onClick={() => setIsLoadingBar(true)}
          className="rounded-full border-1 border-[#F4D0C4] bg-white px-5 py-[5px] text-[16px] font-semibold text-orange-500 transition-all hover:border-orange-500 sm:text-[13px]"
          href={SIDEBAR_MENU_LINK.aiModel}
        >
          View all
        </Link>
      </div>
      {isLoading ? (
        <ModelSkeleton />
      ) : (
        <div className="grid grid-cols-5 gap-4 2xl:grid-cols-4 md:grid-cols-2 sm:gap-2">
          {models?.data?.items?.map((model: Lora) => (
            <div key={model.id} className="2xl:last:hidden">
              <CardModel
                id={model.id}
                totalUse={model.runCount}
                url={model.previews[0] || ''}
                title={model.name}
                owner={model.creator}
                totalLike={model.likeCount}
                type={model.type}
                baseModel={model.baseModel}
                model={model}
              />
            </div>
          ))}
        </div>
      )}
    </div>
  );
};

export default Models;
