import { Skeleton } from "@nextui-org/react";

const ExploreSkeleton = () => {
  return (
    <div>
      <div className="hidden sm:block">
        <div className="flex -mx-2">
          <div className="w-[50%] px-2">
            <Skeleton className="mb-4 h-[320px] w-full rounded-2xl" />
            <Skeleton className="mb-4 h-[120px] w-full rounded-2xl" />
            <Skeleton className="mb-4 h-[320px] w-full rounded-2xl" />
            <Skeleton className="mb-4 h-[120px] w-full rounded-2xl" />
            <Skeleton className="mb-4 h-[120px] w-full rounded-2xl" />
          </div>
          <div className="w-[50%] px-2">
            <Skeleton className="mb-4 h-[200px] w-full rounded-2xl" />
            <Skeleton className="mb-4 h-[240px] w-full rounded-2xl" />
            <Skeleton className="mb-4 h-[200px] w-full rounded-2xl" />
            <Skeleton className="mb-4 h-[240px] w-full rounded-2xl" />
            <Skeleton className="mb-4 h-[240px] w-full rounded-2xl" />
          </div>
        </div>
      </div>
      <div className="sm:hidden">
        <div className="flex -mx-2">
          <div className="w-[20%] px-2 xl:hidden">
            <Skeleton className="mb-4 h-[100px] w-full rounded-2xl" />
            <Skeleton className="mb-4 h-[240px] w-full rounded-2xl" />
            <Skeleton className="mb-4 h-[100px] w-full rounded-2xl" />
            <Skeleton className="mb-4 h-[240px] w-full rounded-2xl" />
          </div>
          <div className="w-[20%] px-2  xl:w-[50%] md:w-[100%]">
            <Skeleton className="mb-4 h-[320px] w-full rounded-2xl" />
            <Skeleton className="mb-4 h-[120px] w-full rounded-2xl" />
            <Skeleton className="mb-4 h-[320px] w-full rounded-2xl" />
            <Skeleton className="mb-4 h-[120px] w-full rounded-2xl" />
            <Skeleton className="mb-4 h-[120px] w-full rounded-2xl" />
          </div>
          <div className="w-[20%] px-2 xl:hidden">
            <Skeleton className="mb-4 h-[200px] w-full rounded-2xl" />
            <Skeleton className="mb-4 h-[240px] w-full rounded-2xl" />
            <Skeleton className="mb-4 h-[200px] w-full rounded-2xl" />
            <Skeleton className="mb-4 h-[240px] w-full rounded-2xl" />
            <Skeleton className="mb-4 h-[240px] w-full rounded-2xl" />
          </div>
          <div className="w-[20%] px-2 xl:w-[50%] md:hidden">
            <Skeleton className="mb-4 h-[240px] w-full rounded-2xl" />
            <Skeleton className="mb-4 h-[150px] w-full rounded-2xl" />
            <Skeleton className="mb-4 h-[240px] w-full rounded-2xl" />
            <Skeleton className="mb-4 h-[150px] w-full rounded-2xl" />
            <Skeleton className="mb-4 h-[150px] w-full rounded-2xl" />
          </div>
          <div className="w-[20%] px-2 xl:hidden">
            <Skeleton className="mb-4 h-[120px] w-full rounded-2xl" />
            <Skeleton className="mb-4 h-[160px] w-full rounded-2xl" />
            <Skeleton className="mb-4 h-[120px] w-full rounded-2xl" />
            <Skeleton className="mb-4 h-[160px] w-full rounded-2xl" />
            <Skeleton className="mb-4 h-[160px] w-full rounded-2xl" />
          </div>
        </div>
      </div>
    </div>
  );
}

export default ExploreSkeleton;