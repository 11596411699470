import { useSearchParams } from 'next/navigation';
import React, { useEffect, useRef, useState } from 'react';

import useCreateQueryString from '@/hooks/useCreateQueryString';
import { IconLeft, IconRight } from '@/assets';
import { debounce } from 'lodash';

const HotTagList: React.FC<{
  list: { id: number; content: string }[];
}> = ({ list }) => {
  const scrollContainerRef = useRef<any>(null);
  const scrollContentRef = useRef<any>(null);
  const [hasScroll, setHasScroll] = useState(false);
  const [isAtStart, setIsAtStart] = useState(true);
  const [isAtEnd, setIsAtEnd] = useState(false);
  const searchParams = useSearchParams();
  const createQueryString = useCreateQueryString();
  const paramTag = searchParams.get('tag') ?? '';

  const isTagSelected = (tag: string) => paramTag === tag;

  const onClickTag = (tag: number) => {
    if (isTagSelected(tag.toString())) {
      createQueryString('tag', '', { scroll: false });
    } else {
      createQueryString('tag', tag, { scroll: false });
    }
  };

  const scroll = (direction: 'next' | 'prev') => {
    if (scrollContainerRef.current) {
      const { scrollLeft, clientWidth } = scrollContainerRef.current;
      const scrollAmount = direction === 'next' ? clientWidth : -clientWidth;
      scrollContainerRef.current.scrollTo({ left: scrollLeft + scrollAmount, behavior: 'smooth' });
    }
  };

  useEffect(() => {
    if (scrollContainerRef.current) {
      scrollContainerRef.current.onscroll = () => {
        const { scrollLeft, clientWidth, scrollWidth } = scrollContainerRef.current;
        setIsAtStart(scrollLeft === 0);
        setIsAtEnd(scrollLeft + clientWidth >= scrollWidth);
      };
    }
  }, []);

  useEffect(() => {
    const handleShowNav = () => {
      if (!scrollContainerRef.current || !scrollContentRef.current) return;
      const isShow = scrollContentRef.current.clientWidth >= scrollContainerRef.current.clientWidth;
      setHasScroll(isShow);
    };
    handleShowNav();

    window.addEventListener('resize', debounce(handleShowNav, 500));

    return () => {
      window.removeEventListener('resize', handleShowNav);
    };
  }, [list]);

  return (
    <div className="relative w-full">
      <div ref={scrollContainerRef} className="overflow-x-auto scrollbar-hide">
        <div className="inline-flex items-center" ref={scrollContentRef}>
          {list.map((tag) => (
            <div
              onClick={() => onClickTag(tag.id)}
              className="mr-1 cursor-pointer hover:opacity-70"
              key={tag.id}
            >
              <div
                aria-checked={isTagSelected(tag.id.toString())}
                className="truncate rounded-[100px] border-1 border-gray-200 bg-white px-[14px] py-[6px] text-[14px] sm:text-[13px] text-black aria-checked:border-black"
              >
                {tag.content}
              </div>
            </div>
          ))}
        </div>
      </div>
      <div
        aria-checked={!isAtStart && hasScroll}
        className="absolute sm:hidden cursor-pointer rounded-full invisible opacity-0 transition-all aria-checked:visible aria-checked:opacity-100 overflow-hidden -left-[8px] -top-[1px] -bottom-[1px] w-[40px] from-white bg-gradient-to-r flex justify-start items-center"
        onClick={() => scroll('prev')}
      >
        <IconLeft className="scale-[0.6] -ml-[6px]" />
      </div>
      <div
        aria-checked={!isAtEnd && hasScroll}
        className="absolute sm:hidden cursor-pointer rounded-full invisible opacity-0 transition-all aria-checked:visible aria-checked:opacity-100 overflow-hidden -right-[8px] -top-[1px] -bottom-[1px] w-[40px] from-white bg-gradient-to-l  flex justify-end items-center"
        onClick={() => scroll('next')}
      >
        <IconRight className="scale-[0.6] -mr-[6px]" />
      </div>
    </div>
  );
};

export default HotTagList;
