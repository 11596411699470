import UsecasePage from '@/app/template/UsecaseContent';
import ClientOnly from '@/components/client-only';
import React from 'react';
import ExploreSkeleton from './Skeleton';

const ExploreTemplate: React.FC = () => {
  return (
    <div className="my-[25px]">
      <div className="flex items-center justify-between mb-4">
        <h3 className="text-[24px] font-semibold sm:text-[16px]">Explore</h3>
      </div>
      <ClientOnly skeleton={<ExploreSkeleton />}>
        <UsecasePage />
      </ClientOnly>
    </div>
  );
}

export default ExploreTemplate;