import { Skeleton } from "@nextui-org/react";

const ModelSkeleton = ({ items = 5, cols = 5 }) => {
  const arr = Array.from(Array(items).keys());

  return (
    <div className={`grid gap-4 sm:gap-2 grid-cols-${cols} 2xl:grid-cols-4 md:grid-cols-2`}>
      {arr.map((item) => (
        <div key={item} className="rounded-[8px] cursor-pointer border-1 border-[#E8E8E8] bg-white p-2 2xl:last:hidden">
          <div className="before:block before:pb-[calc((240/210)*100%)] rounded-[8px] relative overflow-hidden">
            <Skeleton className="absolute object-top top-0 left-0 w-full h-full" />
          </div>
          <div className="mt-3">
            <Skeleton className="h-6 rounded-md" />
            <div className="flex items-center justify-between mt-2">
              <Skeleton className="h-6 rounded-md w-[60%]" />
              <Skeleton className="h-6 rounded-md w-[30%]" />
            </div>
          </div>
        </div>
      ))}
    </div>
  );
}

export default ModelSkeleton;