import { api } from '../axios';

export const getTopAig = async ({ pageSize, offset }: { pageSize: number; offset: number }) => {
  return await api.get(`/leaderboard/top-aig?size=${pageSize}&offset=${offset}`);
};

export const getTopAigEventXmas = async ({
  pageSize,
  offset,
}: {
  pageSize: number;
  offset: number;
}) => {
  return await api.get(
    `/leaderboard/top-user?size=${pageSize}&offset=${offset}&filter={"from":"2023-11-28 11:00:00.000","to":"2023-12-05 16:00:00.000"}`,
  );
};

export const getTopPrompt = async ({ pageSize, offset }: { pageSize: number; offset: number }) => {
  return await api.get(`/leaderboard/top-prompts?size=${pageSize}&offset=${offset}`);
};

export const getTopArtwork = async ({ pageSize, offset }: { pageSize: number; offset: number }) => {
  return await api.get(`/leaderboard/top-mints?size=${pageSize}&offset=${offset}`);
};

export const getMyRankAig = async (address: string) => {
  return await api.get(`/aig-point/rank/${address}`);
};

export const getMyRankWithType = async (address: string, type: 'mint' | 'prompt' | 'aig') => {
  return await api.get(`leaderboard/${type}/${address}/check-rank`);
};

export const getTopAssetLike = async ({
  pageSize,
  offset,
}: {
  pageSize: number;
  offset: number;
}) => {
  return api.get(`/leaderboard/top-like?size=${pageSize}&offset=${offset}`);
};

export const postBurnAig = async () => api.post('/aig-point/burn-aig');

export const getBurnAig = async () => api.get('/aig-point/burn-aig');

export const getTopCreatorGen = async ({
  pageSize,
  offset,
}: {
  pageSize: number;
  offset: number;
}) => {
  return api.get(`/leaderboard/top-creator-gen?size=${pageSize}&offset=${offset}`);
};
