'use client';

import 'swiper/css';
import 'swiper/css/navigation';

import { usePathname, useSearchParams } from 'next/navigation';
import React, { useEffect } from 'react';

import ListBarUsecase from '@/components/list-bar/ListBarUsecase';
import useCreateQueryString from '@/hooks/useCreateQueryString';
// import cachedLocalStorage from '@/libs/localStorage';
import { useRouter } from '@/provider/RouterProvider';
import { useAuth } from '@/provider/UserProvider';

import ListUsecase from './ListUsecase';
import useUseCase from './useUseCase';

enum CategoryKey {
  Hot = 'hot',
  New = 'new',
}

const CATEGORIES = [
  { key: CategoryKey.Hot, title: 'Hot' },
  { key: CategoryKey.New, title: 'New' },
];

const UsecasePage = () => {
  const [openFilter, setOpenFilter] = React.useState(false);
  const router = useRouter();
  const pathname = usePathname();
  const searchParams = useSearchParams();
  const createQueryString = useCreateQueryString();
  const categoryParam = searchParams.get('category');
  const { isLoginClient } = useAuth();

  // const isFilterNsfwStorage = cachedLocalStorage.getItemStr('isFilterNsfw');
  const isFilterNsfwStorage = false;

  const [isFilterNsfw, setIsFilterNsfw] = React.useState<boolean>(false);

  const { list, loading, currentPage, loadMore } = useUseCase();

  const handleFilterCategory = (key: CategoryKey) => {
    switch (key) {
      case CategoryKey.Hot:
        createQueryString(
          ['category', 'weekViews', 'createdAt'],
          [key, 'DESC', ''],
          { scroll: false },
          ['assetCategory', 'tags', 'tagIds'],
        );
        break;
      case CategoryKey.New:
        createQueryString(
          ['category', 'weekViews', 'createdAt'],
          [key, '', 'DESC'],
          { scroll: false },
          ['assetCategory', 'tags', 'tagIds'],
        );
        break;
      default:
        createQueryString(['category', 'weekViews', 'createdAt'], [key, 'DESC', '']);
        break;
    }
  };

  const handleClearFilter = React.useCallback(() => {
    setOpenFilter(false);
    router.push(pathname === '/' ? '//' : pathname, { scroll: false }, { noLoadBar: true });
  }, []);

  useEffect(() => {
    if (!isLoginClient || !isFilterNsfwStorage) {
      setIsFilterNsfw(false);
    }
  }, [isLoginClient, isFilterNsfwStorage]);

  return (
    <div>
      <div className="">
        <div className="mb-3 flex items-center sm:mb-2">
          {CATEGORIES.map((item, index) => (
            <span
              aria-expanded={index > 0}
              key={index}
              aria-checked={categoryParam === item.key || (index === 0 && !categoryParam)}
              onClick={() => handleFilterCategory(item.key)}
              className="cursor-pointer text-[20px] font-bold leading-[1.2] text-gray-400 aria-checked:text-black aria-expanded:ml-4 md:aria-expanded:ml-3 sm:text-[15px]"
            >
              {item.title}
            </span>
          ))}
        </div>
        <div className="">
          <ListBarUsecase
            handleClearFilter={handleClearFilter}
            openFilter={openFilter}
            setOpenFilter={setOpenFilter}
            isFilterNsfw={isFilterNsfw}
            setIsFilterNsfw={setIsFilterNsfw}
          />
          <div className="mt-4">
            <ListUsecase
              openFilter={openFilter}
              setOpenFilter={setOpenFilter}
              list={
                !isFilterNsfw
                  ? list.filter(
                      (item) => item.processingAsset && item.processingAsset.isNsfw === false,
                    )
                  : list
              }
              loadMore={loadMore}
              currentPage={currentPage}
              // getUseCaseURL={getUseCaseURL}
              loading={loading}
            />
          </div>
        </div>
      </div>
    </div>
  );
};

export default UsecasePage;
