import { MagicIcon, VerifyAccount } from "@/assets";
import AvatarDecor from "@/components/avatar-decor";
import AvatarPlaceholder from "@/components/avatar-default";
import { getTopCreatorGen } from "@/services/leaderboard";
import { UserProfile } from "@/types/user";
import { formatCash, useSQuery } from "@/utils";
import Link from "next/link";
import CreatorSkeleton from "./Skeleton";

const Creator = () => {
  const { data, isLoading } = useSQuery({
    queryKey: ['topCreatorGen'],
    queryFn: () => getTopCreatorGen({ pageSize: 18, offset: 0 }),
    keepPreviousData: true,
    staleTime: 5000,
  });

  const list: UserProfile[] = data?.data?.items || [];

  return (
    <div className="mt-[25px]">
      <div className="mb-4">
        <h3 className="text-[24px] font-semibold sm:text-[16px]">Top Creators</h3>
      </div>
      <div>
        {isLoading ? (
          <CreatorSkeleton />
        ) : (
          <div className="grid rounded-lg px-2 sm:py-1 grid-cols-6 xl:grid-cols-3 md:grid-cols-2 bg-white md:overflow-hidden md:scrollbar-hide md:max-h-[300px] md:overflow-y-auto border-t-1 border-gray-50">
            {list.map((user) => (
              <div key={user.id} className="flex items-center min-h-[73px] md:min-h-[57px] justify-between border-b-1 md:last:border-solid border-gray-50 py-3 md:py-1">
                <div className="flex items-center gap-3">
                  <Link className="w-[44px] sm:w-[36px] sm:h-[36px] h-[44px] rounded-full relative" href={`/profile/${user.publicAddress}`}>
                    <AvatarPlaceholder
                      address={user.publicAddress}
                      src={user.picture}
                      className="rounded-full object-cover w-full h-full"
                    />
                    {user.decoUrl && (
                      <AvatarDecor url={user.decoUrl} scale={24} />
                    )}
                  </Link>
                  <div>
                    <div className="flex items-center gap-1">
                      <Link className="font-medium text-[15px] sm:text-[13px] line-clamp-1" href={`/profile/${user.publicAddress}`}>{user.username || 'Unnamed'}</Link>
                      {(user.isPartner || user.verify) ? <VerifyAccount className="scale-[0.7]" /> : null}
                    </div>
                    <div className="inline-flex items-center mt-1 gap-3 text-[14px] bg-gray-100 rounded-full px-2 py-[2px]">
                      <div className="flex gap-[6px] items-center">
                        <MagicIcon />
                        <p>{formatCash(user.count ?? 0)}</p>
                      </div>
                      {/* <div className="flex gap-[6px] items-center">
                        <IconHeartNotLiked width={20} height={20} />
                        <p>2,3k</p>
                      </div>
                      <div className="flex gap-[6px] items-center">
                        <IconDownload color="#181818" height={18} width={18} />
                        <p>2,3k</p>
                      </div> */}
                    </div>
                  </div>
                </div>
              </div>
            ))}
          </div>
        )}
      </div>
    </div>
  );
}

export default Creator;